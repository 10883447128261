import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GConf from '../../../AssetsM/generalConf';

// function setPageSize() {
//     const style = document.createElement('style');
//     style.innerHTML = `@page {size: landscape}`;
//     style.id = 'page-orientation';
//     document.head.appendChild(style);
// }

function BonEntreSortie(props) {
    let {bonId} = useParams()
    let [articleL, setArticleL] = useState([])
    let [bonES, setBonES] = useState([])

    useEffect(() => {
        //setPageSize()
        axios.post(`${GConf.ApiLink}/stock`, {
            PID : GConf.PID,
            bonId: bonId
          })
          .then(function (response) {
            let target = response.data.filter(item => item.A_Code >= 1n && item.A_Code <= 100n)
            console.log(target)
            setArticleL(target.sort((a, b) => (a.A_Code > b.A_Code ? 1 : -1)))
                
    
          })
    }, [])


    return ( <>
           <div className="container mb-4">
                
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">Designiation</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Ajouter</th>
                            <th scope="col">Qté</th>
                        </tr>
                    </thead>
                    <tbody>
                         {articleL.map( (artData, index) => 
                            <tr key={index}>
                                <th scope="row">{artData.A_Code}</th>
                                <td>{artData.Name}</td>
                                <td>{artData.Prix_vente.toFixed(3)}</td>
                            </tr>
                        )}
                        
                        
                    </tbody>
                </table>
            </div>
           
    </> );
}

export default BonEntreSortie;