import React, { useEffect, useState } from 'react';
import InputLinks from '../Assets/linksData'
import BackCard from '../Assets/backCard'
import { Fade } from 'react-reveal';
import { Button,  Dropdown, Icon, Input, Label, Loader, Tab } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import GConf from '../../AssetsM/generalConf';
import axios from 'axios';
import useGetFamilleArticle from '../../Dashboard/Assets/Hooks/fetchArticlesFamille';



function ModifierPage() {
    /*#########################[Const]##################################*/
    let CmdData = JSON.parse(localStorage.getItem(`Magazin_Gerant_LocalD`));
    let UID = CmdData.CID; 
    const Today = new Date()
    const [commandeD, setCommandeD] = useState({client:'PASSAGER', UID : UID , jour: Today.toISOString().split('T')[0], totale: 0 , articles:[]})
    const [articleNow, setArticleNow] = useState([])
    const [articleList, setArticleList] = useState([])
    const [saveBtnState, setSaveBtnState] = useState(false)
    const [loadingPage, setLoadingP] = useState(true)
    const [loaderState, setLS] = useState(false)
    const [autofocusState, setAutoFocus] = useState(false)
    let Offline = JSON.parse(localStorage.getItem(`Magazin_Gerant_Offline`));
    const panes = [
        {
            menuItem: { key: 'start', icon: 'add circle', content: 'Entrer ' }, 
            render: () => <Tab.Pane attached={false}> <AddArticles    /> </Tab.Pane>,
            
        },
        {
            menuItem: { key: 'articles', icon: 'star', content:  <>Articles <Label className='bg-danger' size='tiny'>{commandeD.articles.length}</Label></> }, 
            render: () => 
                        <Tab.Pane attached={false}>
                            <h5>Listes des Articles</h5>    
                            {commandeD.articles.map( (val) => <ArticleListCard key={val.id} dataA={val}/>)}
                            <br />
                        </Tab.Pane>,
        },
        {
            menuItem: { key: 'client', icon: 'check circle', content: 'Terminer' }, 
            render: () =><>  <Tab.Pane attached={false}><ButtonsCard /></Tab.Pane></>,
        },
        
    ]
    const [familles,allFamille] = useGetFamilleArticle()
    /*#########################[UseEffect]##################################*/
    useEffect(() => {
             
        axios.post(`${GConf.ApiLink}/stock`, {
            tag: GConf.SystemTag,
          }).then(function (response) {
            setArticleList(response.data)
            setLoadingP(false)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment du ancien stock  </div></>, GConf.TostInternetGonf)   
              setLoadingP(false)
            }
        });
    }, [])

    /*#########################[Functions]##################################*/
    const AddArticleToList = ()=>{
        if (!articleNow.A_Code) {toast.error("Code à barre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Name || articleNow.Name == '') {toast.error("Name Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Genre || articleNow.NewPrix == '') {toast.error("Genre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Quantite || articleNow.Quantite == '') {toast.error("Qte Invalide !", GConf.TostErrorGonf)}
        else{
            const searchObject = commandeD.articles.find((article) => article.A_Code == articleNow.A_Code);
            if (searchObject) {
                let IndexOfArticle = commandeD.articles.findIndex((article) => article.A_Code == articleNow.A_Code)
                commandeD.articles[IndexOfArticle].Quantite =  articleNow.Quantite
                commandeD.articles[IndexOfArticle].Genre =  articleNow.Genre
                setArticleNow([{}])
        
                
            } else {
                    let arrayToAdd = {id: commandeD.articles.length+1 , A_Code: articleNow.A_Code, Name: articleNow.Name, Quantite: articleNow.Quantite , Genre: articleNow.Genre }
                    commandeD.articles.push(arrayToAdd)
                    setArticleNow([])
            
            }
        } 
        
    }
    
    const GetArticleData = (value) =>{
        const searchObject= articleList.find((article) => article.A_Code == value);
        if (searchObject) {
            setArticleNow(searchObject)
            setAutoFocus(true)
            
        }else{
            toast.error('Article Indéfine ', GConf.TostSuucessGonf)
        }

        
    }

    const SaveFacture = () =>{
            if (!commandeD.articles || commandeD.articles.length == 0) {toast.error("Client est Invalide !", GConf.TostErrorGonf)}
            else {
                console.log(commandeD.articles)
                setLS(true)
                axios.post(`${GConf.ApiGerantLink}/stock/inventaire/gnr`, {
                    tag: GConf.SystemTag,
                    articleListe: commandeD.articles,
                }).then(function (response) {
                    console.log(response.data)
                    if(response.data.affectedRows) {
                        setSaveBtnState(true)
                        toast.success("Liste  Enregistrer !", GConf.TostSuucessGonf)
                        setLS(false)
                    }
                    else{
                        toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                        setLS(false)
                    }
                }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>Probleme de Connextion</h5> La commande sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                      setLS(false)
                    }
                });

            }       
    }
    const ProgressLoadingBar = (props) =>{
        return(<>
            <div className={`progress-bar-loading ${props.display == true ? '': 'd-none'}`}>
                    <div className="progress-bar-loading-value"></div>
                </div>
            </>)
    }
    /*#########################[Card]##################################*/
    const ArticleListCard = (props) =>{
        return(<>
                <Fade>
                    <div className='card shadow-sm p-2 mb-1 rounded-pill ps-4'>
                        <div className='row'>
                            <div className='col-10 text-start align-self-center'>
                                <div>{props.dataA.Name}</div>
                                 {props.dataA.Quantite}   <span className='bi bi-arrow-left-right'></span> <b className='text-danger'> {props.dataA.Genre} </b>
                            </div>
                           
                            <div className='col-2 align-self-center'><Button disabled={saveBtnState}   icon="times" className='rounded-circle p-2 text-white bg-danger'></Button></div>
                        </div>
                    </div>
                </Fade>

                </>)
    }
    
    const ButtonsCard = () =>{
        return (<>
                <div className='card-body mb-2'>
                    <h5>Buttons</h5>
                    <Button disabled={saveBtnState} className='rounded-pill bg-system-btn' size="large" fluid onClick={SaveFacture}><Icon name='save' /> Enregistrer <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                </div>
        </>)
    }
    
    const AddArticles = () =>{
        return (<>
                <div className=' card-body  mb-2'>
                    <h5>Ajouter article</h5> 
                    <datalist id="articlesList">
                            {articleList.map((test) =>
                            <option key={test.PK} value={test.A_Code}>{test.Name} </option>
                            )}
                    </datalist>
                    <ProgressLoadingBar display={loadingPage} />
                    <Input icon='pin' list="articlesList"   onBlur={ (e) => GetArticleData(e.target.value)} size="large" iconPosition='left' placeholder='Desg'  fluid className='mb-1' />
                    <div className='m-2 text-system'><b> <span className='bi bi-upc '></span> Code a barre : {articleNow.A_Code} </b></div>
                    <div className='m-2 text-system'><b> <span className='bi bi-star-fill '></span> Nom : {articleNow.Name} </b></div>
                    <div className='row mb-2'>
                        <div className='col-8'>
                            <div className='m-2 text-system'><b> <span className='bi bi-currency-dollar '></span> Genre : {articleNow.Genre ?  articleNow.Genre  : ''} </b></div>
                            <div className='m-2 text-system'><b> <span className='bi bi-box-seam-fill '></span> Qte : {articleNow.Quantite} </b></div>
                        </div>
                        
                    </div>
                    <Input type='number' icon='dropbox'    onBlur={ (e) => setArticleNow({...articleNow, Quantite: e.target.value }) } size="large" iconPosition='left' placeholder='Quantite'  fluid className='mb-1' />
                    <h5 className='mb-1 mt-1'>Genre: </h5>
                    <Dropdown className='w-100 shadow-sm rounded mb-3' value={articleNow.Genre} placeholder='Famille' search fluid selection options={familles} onChange={(e, data) => setArticleNow({...articleNow, Genre: data.value })} />
                    <Button fluid  size="large" className='rounded-pill bg-system-btn' onClick={AddArticleToList}>  <Icon name='edit outline' /> Ajouter</Button>
                </div>
        </>)
    }
    return (  <>
        <BackCard data={InputLinks.backCard.nv}/>
        <br />
        <div className='container-fluid'>
            <Tab menu={{  pointing: true  }} panes={panes} />
        </div>
    </>);
}

export default ModifierPage;