import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Icon, Input, Loader } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import FrameForPrint from '../../Dashboard/Assets/frameForPrint';
import usePrintFunction from '../../Dashboard/Assets/Hooks/printFunction';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import { NavLink } from "react-router-dom";
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import SKLT from '../../AssetsM/usedSlk';

function ImprimerRecette() {
    //IMPRIMER / if stock NON regler non

    /*#########################[Const]##################################*/
    const Today = new Date().toISOString().split('T')[0]
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    let [totale, setTotale] = useState('0,000')
    let [totaleDep, setTotaleDep] = useState('0,000')
    let [totaleRev, setTotalRev] = useState('0,000')
    let [bonsList, setBons] = useState([])
    let [selectedDate, setSelectedDate] = useState(Today)
    let [depensesListe, setDepenses] = useState([])
    let [printBtnState, setPrintBtnState] = useState(false)
    let [loaderState, setLS] = useState(true)
    let [waitingTicket, setWaitingTickets] = useState(false)
    const [loading , setLoading] = useState(false)
    
    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiCaisseLink}/rt/imprimer/ancien`, {
          forPID : caisseData.PID,
          camId : CaisseID,
          periode : selectedDate
        })
        .then(function (response) {
            setTotale(response.data[0].Totale)
            setTotaleDep(response.data[0].TotDepense)
            setTotalRev(response.data[0].TotRevenue)
            setBons(response.data[0].TotBons)
            setDepenses(response.data[0].DepListe)
            if ((response.data[0].Waitting).length != 0) { setWaitingTickets(true)}
            setLS(false)
            setLoading(true)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible d'afficher les resultat correct </div></>, GConf.TostInternetGonf) 
              setTotale('0,000')
              setTotaleDep('0,000')
              setLS(false)
              setLoading(true)
            }
        });
      }, [selectedDate])

    /*#########################[Function]##################################*/
    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}
    const MakeBonsSomme = () =>{
        let tot = 0
        bonsList.map((data) =>{
            tot = tot + (data.qte * data.valeur)
        })
        return tot 
     }
     const GetOldRecette = (targetDay) => {
        setSelectedDate(targetDay)
        axios.post(`${GConf.ApiCaisseLink}/rt/imprimer/ancien`, {
            forPID : caisseData.PID,
            camId : CaisseID,
            // periode : new Date(targetDay).toISOString().split('T')[0] 
            periode : targetDay 
          })
          .then(function (response) {
              setTotale(response.data[0].Totale)
              setTotaleDep(response.data[0].TotDepense)
              setBons(response.data[0].TotBons)
              setDepenses(response.data[0].DepListe)
              console.log(response.data[0].TotBons)
              console.log(response.data[0].Waitting)
              if ((response.data[0].Waitting).length != 0) { setWaitingTickets(true)}
              setLS(false)
              setLoading(true)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Impossible d'afficher les resultat correct </div></>, GConf.TostInternetGonf) 
                setTotale('0,000')
                setTotaleDep('0,000')
                setLS(false)
                setLoading(true)
              }
          });
     }
    /*#########################[Card]##################################*/
    const TotaleVente = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 text-center   ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    <h5 className='text-start mb-0'>Totale Ventes [X]</h5>
                    <h1 className='mt-1 mb-1'># {totale} #</h1>
                </div>
            </>)
    }
    const TotaleDepenses = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 text-center   ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    
                    <div className='row'>
                        <div className='col-6 text-start'><h5 className='text-start mb-0'>Depenses & Bons</h5></div>
                        
                    </div>
                    <div className='row mb-3'>
                        <div className='col-6 border-end'><h2 className='mb-0'>{totaleDep}</h2><small>Totale Depenses</small></div>
                        {/* <div className='col-6 '><h2 className='mb-0'>{(parseFloat(totaleDep) + MakeBonsSomme()).toFixed(3)  }</h2><small>Totale Bons</small></div> */}
                        <div className='col-6 '><h2 className='mb-0'>{MakeBonsSomme().toFixed(3)} </h2><small>Totale Bons</small></div>
                    </div> 
                    <h2 className='mb-0 mt-1 '> = {(parseFloat(totaleDep) + MakeBonsSomme()).toFixed(3)  }</h2>
                    <hr />
                    <h3 className='text-start mt-0 mb-1'>Totale Revenue : {totaleRev}</h3>
                </div>
            </>)
    }
    const BonsListeCard = (props) =>{
        return(<>
                <div className={`card p-2 shadow-sm mb-3 rounded-pill  ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : '' }`}>
                    <div className='row'>
                        <div className='col-1 align-self-center text-start'>
                            {props.inKey}
                        </div>
                        <div className='col-4 align-self-center text-center'>
                        <b>{props.data.qte}</b> x {parseFloat(props.data.bon).toFixed(3)} (<small>{parseFloat(props.data.valeur).toFixed(3)}</small>)
                        </div>
                        <div className='col-3 align-self-center'>
                            
                        </div>
                        <div className='col-4 align-self-center'>
                            <b>{(props.data.qte * parseFloat(props.data.valeur)).toFixed(3)}</b>
                        </div>
                    </div>
                </div>
            </>)
    }
    const DeppListeCard = (props) =>{
        return(<>
                <div className={`card p-2 shadow-sm mb-3 rounded-pill  ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : '' }`}>
                    <div className='row'>
                        <div className='col-1 align-self-center text-start'>
                            {/* <Button className='bg-danger rounded-circle text-white' icon onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(props.data.PK)} >
                                <Icon name='trash' />
                            </Button> */}
                            {props.inKey}
                        </div>
                        <div className='col-3 align-self-center '>
                            <span className='ms-3'>{parseFloat(props.data.Valeur).toFixed(3)}</span>
                        </div>
                        <div className='col-8 align-self-center text-start'>
                            <b>{props.data.Description}</b>
                        </div>
                       
                    </div>
                </div>
            </>)
    }

    const TotaleBons = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 text-center   ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    <h5 className='text-start mb-2'>Bons</h5>
                    {loading ?  
                            <>
                                {bonsList.map((data, index) => <BonsListeCard key={index} inKey={index+1} data={data} />)}
                            </>
                    : SKLT.CardList }
                    <h2 className='text-end me-5'> = {MakeBonsSomme().toFixed(3)}</h2>
                    {/* <small className='text-end'><NavLink to='/C/L/rt/depenses' exact='true'>Voire Tous</NavLink></small> */}
                </div>
            </>)
    }
    const TotaleDep = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 text-center   ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    
                    <div className='row mb-4'>
                        <div className='col-6 align-self-center text-start'><h5 className='text-start mb-2'>Liste des Depenses </h5></div>
                        <div className='col-6 align-self-center text-end'>  <NavLink to='/C/L/rt/depenses' exact='true' className='text-white'> <Button size='mini' >Ajouter depenses</Button> </NavLink>  </div>
                    </div>
                    {loading ?  
                            <>
                                {depensesListe.map((data, index) => <DeppListeCard key={index} inKey={index+1} data={data} />)}
                            </>
                    : SKLT.CardList }
                    <h2 className='text-end me-5'> = {totaleDep}</h2>
                     
                </div>
            </>)
    }
    const NetteCard = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 text-center   ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    <h5 className='text-start mb-1'>Nette</h5>
                    {/* <div className='row mb-3'>
                        <div className='col-6 border-end'><h2><CountUp end={parseFloat(totale - totaleDep).toFixed(3)} decimals={3} decimal="," duration={2} /> </h2><small>sans Bons</small></div>
                        <div className='col-6'><h2><CountUp end={parseFloat((totale - totaleDep) - MakeBonsSomme()).toFixed(3)} decimals={3} decimal="," duration={2} /> </h2><small>avec Bons</small></div>
                    </div>  */}
                    <h1 className='mt-0 display-3'><CountUp end={parseFloat(((totale - totaleDep) - MakeBonsSomme()).toFixed(3))  + parseFloat(totaleRev)} decimals={3} decimal="," duration={2} /></h1>
                    {
                        waitingTicket ? <> Vous avez des ticket non terminr </> 
                        :
                        <Button className='bg-danger text-white' disabled={printBtnState} fluid onClick={(e) => PrintFunction('printRecette')}>
                            <Icon name='print' /> Imprimer <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/>
                        </Button>
                    }
                    
                </div>
            </>)
    }

    return ( <>
    <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
        <BackCard data={InputLinks.backCard.rtImpr}/>
        <br />
        <div className='container'>
            
            <div className='row'>
                <div className='col-12 col-lg-5 mb-3'>
                    <div className='card card-body border-div shaow-sm mb-3'>
                        <h5 className='mb-1'>Changer la date </h5>
                        <Input icon='calendar alternate' type='date' size="huge" iconPosition='left'   fluid className='mb-1' value={selectedDate}  onChange={(e) => GetOldRecette(e.target.value)}/>
                    </div>
                    <TotaleVente />
                    <TotaleDepenses />
                    <NetteCard />
                </div>
                <div className='col-12 col-lg-7 mb-3'>
                    <TotaleBons />
                    <TotaleDep />
                </div>
            </div> 
        </div>
    </div>
    <FrameForPrint frameId='printRecette' src={`/Pr/caisse/recette/ancien/${selectedDate}`} />
    </> );
}

export default ImprimerRecette;