import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import GConf from '../../AssetsM/generalConf';

function RecetteCamionTemp() {
    let {DateT} = useParams()
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    let [totale, setTotale] = useState('0,000')
    let [totaleDep, setTotaleDep] = useState([])
    let [bonsList, setBons] = useState([])
    let [totaleRev, setTotaleRev] = useState([])

    let [printBtnState, setPrintBtnState] = useState(false)
    let [loaderState, setLS] = useState(true)

    useEffect(() => {
        // axios.post(`${GConf.ApiCaisseLink}/rt/imprimer`, {
        //     tag: caisseData.PID,
        //     camId : CaisseID,
        //   })
        //   .then(function (response) {
        //     console.log(caisseData)
        //       setTotale(response.data[0].Totale)
        //       setTotaleDep(response.data[0].TotDepense)
        //       setLS(false)
        //   }).catch((error) => {
        //       if(error.request) {
        //         setTotale('0,000')
        //         setTotaleDep('0,000')
        //         setLS(false)
        //       }
        //   });
        axios.post(`${GConf.ApiCaisseLink}/rt/imprimer/temp/ancien`, {
            forPID : caisseData.PID,
            camId : CaisseID,
            periode : DateT,
          })
          .then(function (response) {
              setTotale(response.data[0].Totale)
              setTotaleDep(response.data[0].TotDepense)
              setBons(response.data[0].TotBons)
              setTotaleRev(response.data[0].TotRevenue)
              setLS(false)
          }).catch((error) => {
              if(error.request) {
                setTotale('0,000')
                setTotaleDep('0,000')
                setLS(false)
              }
          });

    }, [])

    const MakeBonsSomme = () =>{
        let tot = 0
        bonsList.map((data) =>{
            tot = tot + (data.qte * data.valeur)
        })
        return tot 
    }
    
    const TotaleBons = () =>{
        const BonsListeCard = (props) =>{
            return(<>
                    <li> <b>{props.data.qte}</b> x {parseFloat(props.data.bon).toFixed(3)} [{props.data.pourcentage} % :  {parseFloat(props.data.valeur).toFixed(3)}]  =  <b>{(props.data.qte * parseFloat(props.data.valeur)).toFixed(3)}</b></li>
                </>)
        }

        return(<>
                <ul>    
                    {bonsList.map((data, index) => <BonsListeCard key={index} inKey={index+1} data={data} />)}  
                </ul>
                <h5 className='mt-0'> = {MakeBonsSomme().toFixed(3)}</h5>
            </>)
    }

    const MakeDepSomme = () =>{
        let tot = 0
        totaleDep.map((data) =>{
            tot = tot + data.Valeur
        })
        return tot 
    }
    const MakeRevSomme = () =>{
        let tot = 0
        totaleRev.map((data) =>{
            tot = tot + data.Reglemment
        })
        return tot 
    }
    const TotaleDep = () =>{
        const DepListeCard = (props) =>{
            return(<>
                    <li> {parseFloat(props.data.Valeur).toFixed(3)}  :   <b> {props.data.Description	}</b></li>
                </>)
        }

        return(<>
                <ul>    
                    {totaleDep.map((data, index) => <DepListeCard key={index} inKey={index+1} data={data} />)}  
                </ul>
                <h5 className='mt-0'> = {MakeDepSomme().toFixed(3)}</h5>
            </>)
    }
    
    const TotaleRev = () =>{
        const RevListeCard = (props) =>{
            return(<>
                    <li> <b> {props.data.Reglemment.toFixed(3)} </b> : {props.data.CL_Name}  </li>
                </>)
        }

        return(<>
                <ul>    
                    {totaleRev.map((data, index) => <RevListeCard key={index} inKey={index+1} data={data} />)}  
                </ul>
                <h5 className='mt-0'> = {MakeRevSomme().toFixed(3)}</h5>
            </>)
    }

    return ( <>
           <div className="container-fluid mb-2">
                <h2 className='text-start mb-1 ' style={{fontSize : 35}}> Le : {new Date(DateT).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </h2> 
                <h3 className='text-start mt-1'>Caisse : {caisseData.CA_Name} </h3> 
                 
                 
                {/* ############################################<br /> */}
                <div style={{border:'2px solid black', padding : 15,  borderRadius : 10}}>
                    1- TOTALE VENTE : 
                    <br />
                    <b>{parseFloat(totale).toFixed(3)}</b>
                    <br />
                     
                    -------------------------------------------<br />
                    2- TOTALE REVENUE : 
                    <br />
                    <TotaleRev />
                    </div>
                    <br />
                 
                 
                {/* ##########################################<br /> */}
                <div style={{border:'2px solid black', padding : 15,  borderRadius : 10}}>
                    3- TOTALE DEPENSE : 
                    <br />
                    <TotaleDep />
                    
                    -------------------------------------------<br />
                    4- LISTE DES BONS : 
                    <br />
                    <TotaleBons />
                    <br />
                </div>
                <br />
                <div style={{border:'2px solid black', padding : 15, borderRadius : 10}}>
                    <div><b style={{fontSize : 20}}>Nett : {(((parseFloat(totale) + MakeRevSomme()) - MakeDepSomme()) - MakeBonsSomme()).toFixed(3) }</b></div>
                    <div><b>Manque :  </b></div>
                </div>
           </div>
    </> );
}

export default RecetteCamionTemp;