import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { Button,  Form, Icon, Input, Loader, Modal, Pagination, TextArea } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb'
import axios from 'axios';
import {toast } from 'react-toastify';
import SKLT from '../../AssetsM/usedSlk';
import useSaveNotification from '../Assets/Hooks/saveNotifFunction';
import TableGrid from '../Assets/tableGrid';

 

function CaisseBons() {
    /*#########################[Const]##################################*/
    const Today = new Date().toISOString().split('T')[0]
    const [familleList, setFamillesList] = useState([]);
    const [displayFamille, setDisplayFamille] = useState([]);
    const [saveBtnState, setSaveBtnState] = useState('')
    const [updateFT, setUpdateFT] = useState('')
    const [familleD, setFamilleD] = useState([])
    const [editfamilleD, setEditFamilleD] = useState([])
    const [modalS, setModalS] = useState(false)
    const [deletemodalS, setDeleteModalS] = useState(false)
    const [loaderState, setLS] = useState(false)
    const [loading , setLoading] = useState(false)
    const [activePage , setActivePage] = useState(1)
    const [fondListCamion, setFonfListCamion] = useState([]);
    const [rechercheDay, setRechercheDay] = useState({start:Today, end:Today});
    const SaveNotification = (genre,tag,table) =>{ useSaveNotification(genre,tag,table)}
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/caisse/bons`, {
            PID : GConf.PID,
          })
          .then(function (response) {
            setFamillesList(response.data)
            setLoading(true)
            setDisplayFamille(response.data.slice(0,5));
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment des familles dans votre ordinateur  </div></>, GConf.TostInternetGonf)   
              setFamillesList(Offline.famille)
              setLoading(true)
              setDisplayFamille(Offline.famille.slice(0,5));
            }
          });
    }, [updateFT])
    
    /*#########################[Function]##################################*/
    const SearchRecette = () =>{
        axios.post(`${GConf.ApiLink}/caisse/searchrecette/all`, {
            PID : GConf.PID,
            targetDay : rechercheDay
        })
        .then(function (response) {                
            setFonfListCamion(response.data)

        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>  </div></>, GConf.TostInternetGonf)   
            }
          });
    }

    const EditFamille = () => {
        setLS(true)
        axios.post(`${GConf.ApiLink}/caisse/bons/modifier`, {
            PID : GConf.PID,
            familleD : editfamilleD,
        }).then(function (response) {
            if(response.data.affectedRows) {
                setModalS(false)
                toast.success("Famille Modifier avec Suucess", GConf.TostSuucessGonf)
                setUpdateFT(Math.random() * 10)
                setLS(false)
                SaveNotification('stockEditFamille',GConf.PID, editfamilleD)
            }
            else{
                setModalS(false)
                toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                setLS(false)
            }
            
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Vous Pouvez pas modifier cette famille </div></>, GConf.TostInternetGonf)   
              setLS(false)
            }
          });
        

    }
    const DeleteFamille = () =>{

    }
    const checkFamilleExistance = () =>{
        if(familleD.Name){
            if(familleD.Name in familleList) {
                toast.error("Famille Deja Exist", GConf.TostErrorGonf)
                setFamilleD({...familleD, Name: '' })
            } 
        }
    }
    const openEditModal = (event,selected) =>{
        setEditFamilleD({PK: event.PK , Name:event.Genre, Description:event.Description})
        selected ? setModalS(true) : setDeleteModalS(true)
    }
    const handlePaginationChange = (e,{ activePage }) =>{
        setActivePage(activePage)
        let start = 5 * (activePage - 1)
        let end = 5*  activePage
        setDisplayFamille(familleList.slice(start, end));
    }


   /*#########################[Card]##################################*/
    const FamilleCard = (props) =>{
        return(<>
                <div className='card p-2 shadow-sm mb-2'>
                    <div className='row'>
                        <div className='col-5'>
                        <div className="d-flex p-0">
                                <div className="flex-shrink-0 align-self-center">
                                    <span className='bi bi-tags-fill bi-md system-color'></span>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h6 className='mb-1'>{props.data.Bon}</h6>
                                    <small> {props.data.B_Remise} %</small>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                                <h6 className='mb-1'>{parseFloat(props.data.B_Valeur).toFixed(3)}</h6>
                                <h6 className='mb-1'>{parseFloat(props.data.B_Nette).toFixed(3)}</h6>
                        </div>
                        <div className='col-2 align-self-center text-end'>
                                <Button  icon='edit outline' size='mini' className='rounded-circle bg-system-btn' onClick={() => openEditModal(props.data,true)}/>
                                <Button  icon='trash alternate' size='mini' className='rounded-circle bg-danger text-white' onClick={() => openEditModal(props.data,false)}/>
                        </div>
                    </div>
                </div>
        </>)
    }
    const MakeTotRechette = (targetList, caisseId) =>{
        let tot = 0
        let wantedByCaisse  = targetList.filter((data) => data.Caisse_ID == caisseId)
        console.log(wantedByCaisse)
        wantedByCaisse.map((data) => {
            tot = tot + data.Final_Value
        })
        return tot.toFixed(3)
    }

    return ( <> 
            <BreadCrumb links={GConf.BreadCrumb.stockFamille} />
            <br />
            <div className="row container">
                <h5 className='text-danger'>Recherche Recette entre : </h5>
                <div className='mb-2 row'>
                    <div className='col-5 col-lg-4'><Input size='large' fluid  type='date' defaultValue={rechercheDay.start} onChange={(e) => setRechercheDay({...rechercheDay, start: e.target.value })} /></div>
                    <div className='col-5 col-lg-4'><Input size='large' fluid  type='date' defaultValue={rechercheDay.end} onChange={(e) => setRechercheDay({...rechercheDay, end: e.target.value })} /></div>
                    <div className='col-2 col-lg-4'><Button fluid className='btn-imprimer' size='large' icon onClick={(e) => SearchRecette()}>  <Icon name='print' /></Button></div>
                </div> 
                <div className='row mt-3'>
                    <div className='col-6 border border-div p-3'>
                        <h3 className='text-start'> Tottale Vente Caisse 1 :</h3>
                        <h1>{MakeTotRechette(fondListCamion, 1111111111)}</h1>
                    </div>
                    <div className='col-6 border border-div p-3'>
                        <h3 className='text-start'> Tottale Vente Caisse 2 :</h3>
                        <h1>{MakeTotRechette(fondListCamion, 2222222222)}</h1>
                    </div>
                     
                </div>
                
                 
                
            </div>
             
        </> );

}

export default CaisseBons;