import React from 'react';
function RapportPage() {
    return ( <>
            <div className='container'>
                <h5>Rapport </h5> 
                <br /> 
                
            </div>
    </> );
}

export default RapportPage;