import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import GConf from '../../AssetsM/generalConf';

function RecetteCamionTemp() {
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    const {CLID} = useParams()
    let [totale, setTotale] = useState('0,000')
    let [totaleDep, setTotaleDep] = useState([])
    let [totaleRev, setTotaleRev] = useState([])
    let [bonsList, setBons] = useState([])

    const [clientD, setClientD] = useState([])
    const [factures, setFactures] = useState([])
     
    const [reglemment, setReglemment] = useState([])
    const [clientCredit, setClientCredit] = useState('0.000');

    useEffect(() => {
         
        axios.post(`${GConf.ApiLink}/client/info`, {
            PID : GConf.PID,
            clientId : CLID
        }).then(function (response) {
                setClientD(response.data[0])
                setClientCredit(response.data[0].Credit)
                setFactures(response.data[0].Facture)
                setReglemment(response.data[0].Reglemment.at(-1))
                
        }) 
    }, [])

    const FactureListCard = () =>{
        const FactureCrade = (props) =>{
            return(<>
                    <li>  <b> Le  {new Date(props.data.T_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )}</b> :  <b>{parseFloat(props.data.Final_Value).toFixed(3)}</b> </li>
                </>)
        }

        return(<>
                <ul>    
                    {factures.map((data, index) => <FactureCrade key={index} inKey={index+1} data={data} />)}  
                </ul>
                 
            </>)
    }
    


    return ( <>
           <div className="container mb-2">
                <h2 className='text-start mb-1'>RESUMER CREDIT : {new Date().toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </h2> 
                <h3 className='text-start mt-1'>CLIENT : {clientD.CL_Name} </h3> 
                <br />
                <br />
                ############################################<br />
                Dernier Reglemment  : 
                <br />
                    {reglemment ? <span><b> {new Date(reglemment.R_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )}</b> :  {parseFloat(reglemment.Reglemment).toFixed(3)} </span>: 'Pas de reglemment '}
                <br />
                <br />
                ############################################<br />
                Liste des Credits : 
                <br />
                <FactureListCard />
                 
                ############################################<br />
                TOTALE : 
                
                <h3> # {clientCredit} #</h3>
                <br />
                <br />
            </div>
            
           
    </> );
}

export default RecetteCamionTemp;