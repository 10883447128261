import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import {  useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dimmer, Icon, Loader, Modal, Popup } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import SKLT from '../../AssetsM/usedSlk';
import FrameForPrint from '../../Dashboard/Assets/frameForPrint';
import usePrintFunction from '../../Dashboard/Assets/Hooks/printFunction';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';


function FactureInfo() {
    /*#########################[Const]##################################*/
    const {CID} = useParams()
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    let [articleL, setArticleL] = useState([])
    let [factureData, setFactData] = useState([])
    let [editState, setEditState] = useState(true)
    let [openEditModal, setOpenEditModal] = useState(false)
    let [secretCode, setSecretCode] = useState('')
    let [loading , setLoading] = useState(false)
    let [loaderState , setLoaderState] = useState(false)
    let Offline = JSON.parse(localStorage.getItem(`Camion_Offline`));
    const [commandeData, setCommandeD] = useState([])
    const [btnState, setBtnState] = useState(false)
    
    /*#########################[UseEffect]##################################*/
    // useEffect(() => {
    //     axios.post(`${GConf.ApiCaisseLink}/rt/factures/select`, {
    //         forPID : caisseData.PID,
    //         fid: FID,
    //         caisseId: CaisseID
    //       })
    //       .then(function (response) {
    //             if(!response.data[0]) {
    //                 toast.error('Facture Introuvable !', GConf.TostSuucessGonf)
    //                 setTimeout(() => {  window.location.href = "/C/L/ft"; }, 2000)
                    
    //             } else {
    //                 setArticleL(JSON.parse(response.data[0].Articles))
    //                 let UsedTableNow = []
    //                 JSON.parse(response.data[0].Articles).map( (article) => {UsedTableNow.push([article.A_Code, article.Qte ])} )
    //                 setFactData(response.data[0])
    //                 setLoading(true)
    //                 if(response.data[0].Cre_Date){setEditState(false)}
    //             }
    //       }).catch((error) => {
    //         if(error.request) {
    //           toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de Charger la facture de son source   </div></>, GConf.TostInternetGonf)   
    //           const FactureTarged = Offline.facture.find((facture) => facture.F_ID == FID);
    //           setLoading(true)
    //           setArticleL(JSON.parse(FactureTarged.Articles))
    //           setFactData(FactureTarged)
    //           setEditState(true)
    //         }
    //       });
    // }, [])

    useEffect(() => {
        axios.post(`${GConf.ApiLink}/commande/info`, {
            PID : GConf.PID,
            CID: CID
          })
          .then(function (response) {
                if (!response.data[0]) {
                    toast.error('Commande Introuvable !', GConf.TostSuucessGonf)
                    setTimeout(() => {  window.location.href = "/C/L/cd"; }, 2000)
                } else {
                    setArticleL(JSON.parse(response.data[0].Articles))
                    console.log(JSON.parse(response.data[0].Client))
                    setCommandeD(response.data[0])
                    setLoading(true)
                     
                      
                }  
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger la commande   </div></>, GConf.TostInternetGonf)   
              setLoading(true)
               
            }
          });
    }, [])

    /*#########################[Function]##################################*/
    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}

    const CalculateTVA =  (value) =>{
        const facteur_p = (100 / (GConf.DefaultTva + 100));
        return (parseFloat(value) * facteur_p).toFixed(3) 
    }

    /*#########################[Card]##################################*/
    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'Payee': return <StateCard color='success' text='Payeé' />;  
            case 'Credit': return <StateCard color='danger' text='Credit' /> ;
            case 'Waitting': return <StateCard color='warning' text='En Attend' /> ;
            default:  return <StateCard color='secondary' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <span>
            {statusCard()}
          </span>
        );
    }
    
    const TotaleCard = () =>{
        return(<>
                {/* <div className={`card card-body shadow-sm mb-2 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    <h5>Nette & Totale </h5>
                    <div>Nette A Payer : {loading ? factureData.Final_Value.toFixed(3) : SKLT.BarreSkl }</div>
                    <div>Etat de la Facture : {loading ? <StateCard status={factureData.Pay_State} /> : SKLT.BarreSkl }</div>
                    <div>Mode De Paymment : {loading ? factureData.Paye_Bons == '' ? 'Espéce' : 'Par Bons' : SKLT.BarreSkl }</div>
                </div> */}
        </>)
    }
    const BtnsCard = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                    <h5>Controle</h5>
                    <div className='row mb-2'>
                    <div className='col-12 mb-2'>
                            {/* <Button as='a' href={`/C/L/ft/edit/${CID}`} animated disabled={false} className='rounded-pill bg-system-btn'  fluid> */}
                            <Button  animated disabled={false} className='rounded-pill bg-system-btn'  fluid>
                                <Button.Content visible><Icon name='edit outline' /> Facturer Et Payeé  </Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow right' />
                                </Button.Content>
                            </Button>
                    </div>
                    <div className='col-12 mb-2'>
                        <Button  className='rounded-pill bg-danger text-white'  fluid  ><Icon name='trash' /> Demande d'annulation </Button>
                    </div>
                    <div className='col-12 mb-2'>
                        <Button  className='rounded-pill btn-imprimer'  fluid onClick={(e) => PrintFunction('printFacture')}><Icon name='print' /> Imprimer</Button>
                    </div>
                    </div>
                </div>
        </>)
    }
    const FactureHeader = () =>{
        return(<>
                <h2 className='text-center mb-4'>Commande </h2> 
                <br />
                <div className='row'>
                    
                    <div className='col-5'>
                        <div className='text-secondary'><b>CODE COMMANDE : </b> {CID}</div>
                        <div className='text-secondary'><b>Passé Le  : </b> {loading ?  new Date(commandeData.Date_Passe).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ) : SKLT.BarreSkl } </div>
                    </div>
                    <div className='col-7'>
                        
                        <div className='text-secondary'><b>CLIENT: </b> {loading ?  <> {JSON.parse(commandeData.Client).Name}</> : SKLT.BarreSkl } </div>
                        <div className='text-secondary'><b>Phone: </b> {loading ?  <> {JSON.parse(commandeData.Client).Phone}</> : SKLT.BarreSkl } </div>
                        <div className='text-secondary'><b>Adresse: </b> {loading ?  <> {JSON.parse(commandeData.Client).Adress}</> : SKLT.BarreSkl } </div>
                    </div>
                </div>
        </>)
    }
    const EnterKyPressed = (e) =>{
        if (e.which == 13) {
            setLoaderState(true)
            if (e.target.value == '29102024') {
                axios.post(`${GConf.ApiCaisseLink}/mf/detele`, {
                    forPID : caisseData.PID,
                    FID: CID,
                    factureD: factureData
                  })
                  .then(function (response) {
                        setOpenEditModal(false)
                        setLoaderState(false)
                        toast.error('Facture Supprimeé !', GConf.TostSuucessGonf)
                        setTimeout(() => {  window.location.href = "/C/L/ft"; }, 1000)
                  }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de Charger la facture de son source   </div></>, GConf.TostInternetGonf)   
                      setOpenEditModal(false)
                       setLoaderState(false)
                    }
                  });
                
            } else {
                alert('Code Incorrect')
                setOpenEditModal(false)
                setLoaderState(false)
            }
            
            
             
         }  
         else {
             //console.log(e.which)
         } 
            
    }
    return ( <>
    <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2 text-white' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
        <BackCard data={InputLinks.backCard.cdInfo}/>
        
        <br />
        <br />
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-lg-7'>
                    <FactureHeader />
                    <br />
                    <br />
                    <div className="table-responsive">
                    <table className={`table ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2 text-white' : '' }`}>
                        <thead>
                            <tr>
                            <th scope="col">No</th>
                            <th scope="col">Designiation</th>
                            <th scope="col">Qté</th>
                            <th scope="col">PU</th>
                            <th scope="col">Totale</th>
                             
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?  
                            <>
                            {articleL.map( (artData, index) => 
                                <tr key={artData.id}>
                                    <th scope="row">{index + 1}</th>
                                    {/* <td>{artData.Name}</td> */}
                                    <td><Popup position='left center' content={<img src={`https://assets.ansl.tn/Images/Articles/${artData.Photo_Path}`} className='img-responsive' width='120px' />} trigger={<b role="button">{artData.Name}</b>} /></td>
                                    <td>{artData.qte}</td>
                                    <td>{artData.PU.toFixed(3)}</td>
                                    <td>{artData.pTot.toFixed(3)}</td>
                                     
                                </tr>
                            )}
                            </>
                            : SKLT.FactureList }
                            
                        </tbody>
                    </table>
                </div>
                    <br />
                    <br />
                </div>
                <div className='col-12 col-lg-5'>
                    <Bounce bottom>
                            <div className="sticky-top" style={{top:'70px'}}>
                                <TotaleCard />
                                <BtnsCard />
                            </div>
                    </Bounce>
                </div>
            </div>
        </div>
    </div>
    <Modal
            size='mini'
            open={openEditModal}
            
            dimmer = 'blurring'
            onClose={() => setOpenEditModal(false)}
            onOpen={() => setOpenEditModal(true)}
        >
            <Modal.Content  >
                <div>
                        <Dimmer active={loaderState} inverted className='border-div'>
                            <Loader inverted> </Loader>
                        </Dimmer>
                        <h4>Scanner le Code de permission : </h4>
                        <Loader  active={loaderState} inline size='tiny' className='ms-2 text-danger '/>
                        <input type='text' className='form-control form-control-lg'  autoComplete="off"   onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} />
                 </div>
            </Modal.Content>
            <Modal.Actions>
                <div className='row'>
                    {/* <div className='col-6 align-self-center  text-start'> <Loader inverted={true}  active={false} inline size='tiny' className='ms-2 text-danger '/></div> */}
                    <div className='col-12 align-self-center text-end'> <Button className='rounded-pill' negative onClick={ () => setOpenEditModal(false)}> <span className='bi bi-x' ></span> Fermer  </Button></div>
                </div>
                
                
            </Modal.Actions>
    </Modal>
    <FrameForPrint frameId='printFacture' src={`/Pr/caisse/facture/${CID}`} />
    </> );
}

export default FactureInfo;