const AlimDefaultCateg = [
    {id:0, page:1, genre:'INITIALE', color:'#9430cf', articles:[{id:1, simple:true , dim:3,  codeAB:'6191513502212', heigPhoto:'155', widtPhoto:'80'},]},
    {id:1, page:2, genre: "CONSERVATION 1", color: "#c9263f", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191444400496", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194055500135", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220252", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220245", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000101066", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000101059", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220115", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220078", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400274", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400397", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400267", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194055500517", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100694", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100663", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100595", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194055500630", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:2, page:3, genre: "CONSERVATION 2", color: "#c9263f", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194000240192", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000240505", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000240178", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194019202440", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400821", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191586400217", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400791", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400982", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400180", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400203", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400144", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6987649843271", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400724", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100656", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400229", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220016", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:3, page:4, genre:'JOUDA', color:'#f04c41', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194001480412', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6194000101066', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6194055500135', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6194024504126', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6194003220252', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191444400496', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6194001480405', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6194003220245', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6194000101059', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6194024504119', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6194055500234', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6194001480641', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6194001420067', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191444400250', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6194024504072', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6194001481433', heigPhoto:'155', widtPhoto:'80'},

    ]},
    {id:4, page:5, genre:'ABIDA', color:'#3b75d9', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194024504119', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6194024504126', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6194024504072', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6194024504089', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:5, page:6, genre: "THON & SARDINE 1", color: "#69941f", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191444400304", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571803177", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700324", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700232", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700188", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700638", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "619500700041", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194029100187", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191506801575", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801555", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191463401306", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194029100675", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400298", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191519600103", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191519600202", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191519600127", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:6, page:7, genre: "THON & SARDINE 2", color: "#69941f", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191519600301", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191519600035", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191519600240", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400342", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400304", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700300", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700584", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700287", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700638", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700607", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:7, page:8, genre: "LA GOULETTE", color: "#25a0c2", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191571800947", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800923", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800961", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801180", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801432", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801128", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800947", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800923", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800961", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801180", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801432", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801128", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id:8, page:9, genre: "SIDI DAOUD", color: "#0c7d14", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191520002637", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002033", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002996", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002095", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001982", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000626", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002095", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002033", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001975", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002989", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002002", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000046", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000367", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000251", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000251", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002620", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id:9, page:10, genre: "CHAMIA 1", color: "#726980", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:10, page:11, genre: "CHAMIA 2", color: "#726980", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:11, page:12, genre:'SHEHRAZED', color:'#5dd620', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194036802258', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194036802227', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194036802326', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194036802272', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194036800438', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194036800506', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194036800476', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194036801572', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194036802340', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194036802265', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194036802197', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194036802319', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194036800902', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194036801374', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194036800810', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194036800940', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:12, page:13, genre:'SHEHRAZED', color:'#5dd620', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194036802258', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194036802227', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194036802326', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194036802272', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194036800438', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194036800506', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194036800476', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194036801572', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194036802340', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194036802265', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194036802197', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194036802319', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194036800902', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194036801374', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194036800810', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194036800940', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:13, page:14, genre: "LE MOULIN DOR", color: "#d92537", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194002430195", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:14, page:15, genre: "LE MOULIN DOR", color: "#d92537", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6194002430195", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:15, page:16, genre:'SAIDA 1', color:'#f5162c', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194008511010', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194008554963', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194008555380', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194008515520', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194008554826', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194008554833', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194008551030', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194008552204', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194008543028', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194008555274', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194008518040', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194008518026', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194008555298', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194008554482', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194008516107', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194008555335', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:16, page:17, genre:'SAIDA 2 ', color:'#f5162c', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194008511010', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194008554963', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194008555380', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194008515520', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194008554826', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194008554833', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194008551030', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194008552204', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194008543028', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194008555274', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194008518040', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194008518026', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194008555298', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194008554482', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194008516107', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194008555335', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:17, page:18, genre:'LBM', color:'#ddf013', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:18, page:19, genre: "TOM", color: "#2e49bf", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:19, page:20, genre: "CHOCOLAT", color: "#381f0d", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191402902857", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191402902604", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448623", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005420186", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100531", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544101224", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005412068", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "16194005420213", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005412051", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005461110", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005412082", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100616", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100418", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:20, page:21, genre: "SAID", color: "#850314", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194005420100", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "619400542155", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400812", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400874", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005451227", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452002", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400911", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453030", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452026", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452040", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005401123", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005420049", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453047", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453023", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453016", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005451043", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:21, page:22, genre: "MAESTRO", color: "#05239e", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194005448449", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448487", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448562", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544104072", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446520", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446407", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447909", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447930", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005449224", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005449217", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447916", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447947", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005445011", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446353", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448418", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447961", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:22, page:23, genre:'ARTISTO', color:'#33070d', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191448600120', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191448600557', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191448600540', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191448600984', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191448600670', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191448600694', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191448600939', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191448602377', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:23, page:24, genre:'PRESIDENT', color:'#85a5ff', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191513502212', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191513501017', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191513501086', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191513502014', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6191513502489', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191513501222', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191513504384', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191513501062', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191513504278', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191513502045', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191513504520', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191513504438', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191513501574', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191513503363', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191513501277', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191513504445', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:24, page:25, genre:'PRESIDENT', color:'#85a5ff', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191513502212', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191513501017', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191513501086', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191513502014', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6191513502489', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191513501222', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191513504384', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191513501062', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191513504278', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191513502045', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191513504520', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191513504438', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191513501574', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191513503363', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191513501277', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191513504445', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:25, page:26, genre:'PATTES 1', color:'#ba9027', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:26, page:27, genre:'PATTES 2 ', color:'#ba9027', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:27, page:28, genre:'WARDA', color:'#fcb36a', articles:[
        {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
        {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
        {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
        {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
        {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
        {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
        {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:28, page:29, genre:'VANOISE', color:'#04acc9', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:29, page:30, genre:'SMART CHEF 1', color:'#ffc847', articles:[
                {id:1, simple:true , dim:3, codeAB:'61940581', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'61940574', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191514001257', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191514004265', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'61940529', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191514002001', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191514004807', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:30, page:31, genre:'SMART CHEF 2', color:'#ffc847', articles:[
                {id:1, simple:true , dim:3, codeAB:'61940581', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'61940574', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191514001257', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191514004265', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'61940529', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191514002001', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191514004807', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:31, page:32, genre:'BONNA', color:'#0a6603', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:32, page:33, genre:'BONNA', color:'#0a6603', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:33, page:34, genre: "CAFE", color: "#2c302c", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191467800136", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800129", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191593800567", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610021086", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8901036122419", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800037", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610021079", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "843161002146", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020928", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020881", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191557800015", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191557800022", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:34, page:35, genre: "CAFE", color: "#2c302c", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:35, page:36, genre: "DIVERS", color: "#de1077", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:36, page:37, genre: "DIVERS", color: "#93179c", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:37, page:38, genre: "DIVERS", color: "#fa3c07", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:38, page:39, genre: "DIVERS", color: "#10ada0", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:39, page:40, genre: "DIVERS", color: "#c92a4a", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
]
export default AlimDefaultCateg