import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Icon, Input, Loader } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb';
import usePrintFunction from '../Assets/Hooks/printFunction';
import FrameForPrint from '../Assets/frameForPrint';
import useSaveNotification from '../Assets/Hooks/saveNotifFunction';

const AddArticles = ({tableData,GetArticleData, loadingPage, EnterKyPressed,articleNow,setArticleNow,updateBtnState,AddArticleToList, focusPrixA,focusPrixV,focusQte}) =>{
    
    const ProgressLoadingBar = (props) =>{
        return(<>
            <div className={`progress-bar-loading ${props.display == true ? '': 'd-none'}`} style={{marginTop:'2px'}}>
                    <div className="progress-bar-loading-value bg-danger"></div>
                </div>
            </>)
    }
    const UpdateQteFunction = () => {
        if (!articleNow.A_Code) {toast.error("Code à barre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Name || articleNow.Name === '') {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Prix_achat || articleNow.Prix_achat === '') {toast.error("Achat Invalide !", GConf.TostErrorGonf)}
        else{
            axios.post(`${GConf.ApiLink}/stock/modifier/Qte`, {
                article: articleNow.A_Code,
                Qte: articleNow.Quantite,
              })
              .then(function (response) {    
                    toast.success("Qte Ajouteé !", GConf.TostSuucessGonf)
                    setArticleNow({ A_Code: null , Prix_achat: '', Prix_vente: '', Quantite: '' });
              }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> Les Ticket n'ont pas étéz enregistreé </div></>, GConf.TostInternetGonf)   
                  
                }
              });
        }
    }
    return (<>
            <div className='card card-body  mb-2 border-div'>
                
                <h5>Ajouter article</h5> 
                <datalist id="articlesList">
                    {tableData.map((test) =>
                    <option key={test.key} value={test.value}>{test.text}</option>
                    )}
                </datalist>
                <ProgressLoadingBar  display={loadingPage} />
                <Input icon='pin' list="articlesList"  onBlur={ (e) => GetArticleData(e.target.value)} size="large" iconPosition='left' placeholder='Entrer article '  fluid className='mb-1 shadow-sm' /> 
                 
                <input type='text' className='form-control form-control-lg mb-2 mt-1' placeholder="Scanner l'article"   onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} />
                { articleNow.A_Code ? 
                    <div className='  text-secondary '><b className='text-ligth'>    {articleNow.Name}  </b></div>
                    :
                    <div className=' text-secondary '><b className='text-ligth'>....................</b></div>
                }
                <div className='row mt-4'>
                    <div className='col-3 align-self-center'>P Achat : </div>
                    <div className='col-9 align-self-center'><Input icon='money bill alternate outline' type='number' value={articleNow.Prix_achat} placeholder='Prix achat'   onChange={ (e) => setArticleNow({ ...articleNow, Prix_achat : e.target.value}) } iconPosition='left'   fluid className='mb-1 shadow-sm' /></div>
                </div>
                <div className='row'>
                    <div className='col-3 align-self-center'>P Vente : </div>
                    <div className='col-9 align-self-center'><Input icon='money bill alternate outline' type='number' value={articleNow.Prix_vente} placeholder='Prix vente'   onChange={ (e) =>  setArticleNow({ ...articleNow, Prix_vente : e.target.value}) } iconPosition='left'   fluid className='mb-1 shadow-sm' /></div>
                </div>
                <div className='row'>
                    <div className='col-3 align-self-center'>Quantite : </div>
                    <div className='col-9 align-self-center'>
                        <div className='row'>
                            <div className='col-8'><Input icon='box' type='number' value={articleNow.Quantite} placeholder='Quantite'    onChange={ (e) => setArticleNow({ ...articleNow, Quantite : e.target.value}) } iconPosition='left'    fluid className='mb-1 shadow-sm' /></div>
                            <div className='col-4'><Button disabled={!articleNow.A_Code} fluid icon className='bg-warning text-white' onClick={() => UpdateQteFunction()} ><Icon name='sign-in alternate' /></Button></div>
                        </div>
                    </div>
                </div>
                
                
                
                
                <br />
                <Button disabled={updateBtnState}  className='rounded-pill bg-primary text-white' onClick={AddArticleToList}>  <Icon name='edit outline' /> Ajouter</Button>
            </div>
    </>)
}

function BonsEntre() {
    /*#########################[Const]##################################*/
    const [bonsD, setBonsD] = useState({genre:'Entre', fournisseur: '', articles:[]})
    const [BE_ID, setBEID] = useState('')
    const [articleNow, setArticleNow] = useState({})
    const [tableData, setTableData] = useState([]);
    const [fournisseurList , setFSList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [toUpdatedList, setTUpList] = useState([]);
    const [loaderState, setLS] = useState(false)
    const [updateBtnState, setUpdateBS] = useState(false)
    const [autoFocus, setAutoFocus] = useState(false)
    const [focusPrixA, setFocusPA] = useState(false)
    const [focusPrixV, setFocusPV] = useState(false)
    const [focusQte, setFocusQte] = useState(false)
     

    const SaveNotification = (genre,tag,table) =>{ useSaveNotification(genre,tag,table)}
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));
    const [loadingPage, setLoadingP] = useState(true)
    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        //get stock
        axios.post(`${GConf.ApiLink}/stock`, {
            PID : GConf.PID,
          })
          .then(function (response) {
               let TableNow = []
               response.data.map( (dta) => {TableNow.push({value : dta.A_Code, text : dta.Name, key: dta.PK})})
               setTableData(TableNow)
               setArticleList(response.data)     
               setLoadingP(false)  
          }).catch((error) => {
            if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Chargement des article sur votre ordinateur  </div></>, GConf.TostInternetGonf)   
                let TableNow = []
                Offline.stock.map( (dta) => {TableNow.push({value : dta.A_Code, text : dta.Name, key: dta.PK})})
                setTableData(TableNow)
                setArticleList(Offline.stock)
            }
        });
        
        axios.post(`${GConf.ApiLink}/fournisseur`, {
            PID : GConf.PID,
          })
          .then(function (response) {
               setFSList(response.data)      
          }).catch((error) => {
            if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Chargement des article sur votre ordinateur  </div></>, GConf.TostInternetGonf)   
            }
        });

    }, [])

    /*#########################[Function]##################################*/
    const AddArticleToList = ()=>{
        if (!articleNow.A_Code) {toast.error("Code à barre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Name || articleNow.Name === '') {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Prix_achat || articleNow.Prix_achat === '') {toast.error("Achat Invalide !", GConf.TostErrorGonf)}
        else{
            const searchObject = bonsD.articles.find((article) => article.A_Code == articleNow.A_Code);
            if (searchObject) {
                let IndexOfArticle = bonsD.articles.findIndex((article) => article.A_Code == articleNow.A_Code)
                bonsD.articles[IndexOfArticle].Quantite =  articleNow.Quantite
                bonsD.articles[IndexOfArticle].Prix_achat =  articleNow.Prix_achat
                bonsD.articles[IndexOfArticle].Prix_vente =  articleNow.Prix_vente
                // toUpdatedList[IndexOfArticle].NewQte = toUpdatedList[IndexOfArticle].NewQte + parseInt(articleNow.Value)
                 
                setArticleNow({ A_Code: '' , Prix_achat: '', Prix_vente: '', Quantite: '' });
                setAutoFocus(false)
            } else {
                 
                let arrayToAdd = {id: articleNow.PK , A_Code: articleNow.A_Code, Name: articleNow.Name,  Quantite: articleNow.Quantite, Prix_achat: articleNow.Prix_achat, Prix_vente: articleNow.Prix_vente }
                bonsD.articles.push(arrayToAdd) 
                let arrayToUpdate = {A_Code : articleNow.A_Code, Name: articleNow.Name, Quantite: articleNow.Quantite, Prix_achat: articleNow.Prix_achat, Prix_vente: articleNow.Prix_vente}
                toUpdatedList.push(arrayToUpdate)
                 
                setArticleNow({ A_Code: '' , Prix_achat: '', Prix_vente: '', Quantite: '' });
                setAutoFocus(false)
            }
        }

    }
    const SaveTicketToPrint = () =>{
        axios.post(`${GConf.ApiCaisseLink}/sk/ticket-prix`, {
            listeDesPrix: bonsD.articles.map(item => `(${item.A_Code})`).join(', '),
          })
          .then(function (response) {    
                toast.success("Ticket Enregistrer !", GConf.TostSuucessGonf)
                 setLS(false)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Les Ticket n'ont pas étéz enregistreé </div></>, GConf.TostInternetGonf)   
              setLS(false)
            }
          });

    }

    const GetArticleData = (value) =>{
        const searchObject = articleList.find((article) => article.A_Code == value);
        if (searchObject) {
            setArticleNow(searchObject);
            setAutoFocus(true)
            
        }else{
            toast.error('NOUVEAUX ARTICLE ', GConf.TostSuucessGonf)
        }
    }
    const UpdateStock = () =>{
        setLS(true)
        setUpdateBS(true)
        axios.post(`${GConf.ApiLink}/stock/update`, {
            PID : GConf.PID,
            artList: bonsD.articles,
            fournisseur: bonsD.fournisseur,
          })
          .then(function (response) {    
            console.log(response.data)
            if(response.data.affectedRows != 0) {
                toast.success("Article Modifier !", GConf.TostSuucessGonf)
                //setBEID(response.data.BE_ID)  
                
                SaveTicketToPrint()
                //setLS(false)   
            }
            else{
                toast.error('Erreur Indéfine ', GConf.TostSuucessGonf)
                setLS(false)
            }
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Le Bon n'as pas été enregistreé </div></>, GConf.TostInternetGonf)   
              setLS(false)
            }
          });

    }
    const RemoveArticleFromList = (value) =>{
        const searchObject= bonsD.articles.findIndex((article) => article.A_Code === value);
        const searchObjectTwo = toUpdatedList.findIndex((article) => article[0] === value);
        bonsD.articles.splice(searchObject, 1);
        toUpdatedList.splice(searchObjectTwo, 1);
        let resteArticles = bonsD.articles;
        setBonsD({...bonsD, articles: resteArticles})
    }
    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}
    
    const EnterKyPressed = (e) =>{
        if (e.which == 13) {
            GetArticleData(e.target.value)
        }  
        //  else if (e.which == 32) {
        //     if (bonsD.articles.length != 0) {
        //         const lastElement = bonsD.articles[factureD.articles.length - 1]
        //         AddMoreQte(lastElement.A_Code,e.target.value)
        //     }
        //  }
         else {
             //console.log(e.which)
         } 
            
    }
    
    const AddByKeyPressed = (e) =>{
        if (e.which == 13) {
            AddArticleToList()
        }              
    }

    /*#########################[Card]##################################*/
    const ArticleListCard = (props) =>{
        return(<>
                    <div className='card shadow-sm p-2 mb-1 border-div  '>
                        <div className='row'>
                            <div className='col-12 col-md-6  text-start align-self-center'>
                                <h5 className='mb-0 text-danger'>{props.dataA.Name}</h5>
                                <small className='mt-0'>{props.dataA.A_Code}</small>
                            </div>
                            <div className='col-10 col-md-5 align-self-center text-start'>
                                <h5> achat: {JSON.parse(props.dataA.Prix_achat).toFixed(3)} | vente:   {JSON.parse(props.dataA.Prix_vente).toFixed(3)} </h5>   
                                {props.dataA.Quantite}   
                            </div>
                            <div className='col-2   col-md-1 align-self-center text-end'><Button disabled={updateBtnState} icon="times" className='rounded-circle p-2 text-white bg-danger' onClick={() => RemoveArticleFromList(props.dataA.A_Code)}></Button></div>
                        </div>
                    </div>
                </>)
    }
    const ButtonsCard = () =>{
        return (<>
                <div className='card card-body  mb-2 border-div'>
                    <h5>Buttons</h5>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Button  className='rounded-pill bg-system-btn' disabled={updateBtnState}  fluid onClick={UpdateStock}><Icon name='edit outline' /> Mettre à jour <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                        </div>
                        {/* <div className='col-5'>
                            <Button  className='rounded-pill btn-imprimer' disabled={!updateBtnState}  fluid onClick={(e) => PrintFunction('printBES')}><Icon name='print' /> Imprimer</Button>
                        </div> */}
                    </div>
                </div>
        </>)
    }
    // const AddArticles = () =>{
    //     return (<>
    //             <div className='card card-body  mb-2 border-div'>
    //                 {/* <h5>Selectioner Fournisseur</h5> 
    //                 <datalist id="fournisseurList">
    //                     {fournisseurList.map((fourniss,index) =>
    //                     <option key={index} value={fourniss.Four_ID}>{fourniss.Four_Name} - {fourniss.Four_Code_Fiscale}</option>
    //                     )}
    //                 </datalist>
    //                 <Input icon='truck' list="fournisseurList"  onChange={ (e) => setBonsD({...bonsD, fournisseur:e.target.value})} size="small" iconPosition='left' placeholder={bonsD.fournisseur}  fluid className='mb-1 shadow-sm' /> 
    //                 */}
    //                 <h5>Ajouter article</h5> 
    //                 <datalist id="articlesList">
    //                     {tableData.map((test) =>
    //                     <option key={test.key} value={test.value}>{test.text}</option>
    //                     )}
    //                 </datalist>
    //                 <Input icon='pin' list="articlesList"  onBlur={ (e) => GetArticleData(e.target.value)} size="small" iconPosition='left' placeholder='Desg'  fluid className='mb-1 shadow-sm' /> 
    //                 <input type='text' className='form-control form-control-lg mb-3' placeholder='Scan article'   onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} />
                    
    //                 <div className='m-2 text-secondary '><b> {articleNow.A_Code}  | {articleNow.Name} </b></div>
 
    //                 <Input icon='asl' type='number' value={articleNow.Prix_achat} placeholder='Prix achat'  onChange={ (e) => setArticleNow({ ...articleNow, Prix_achat : e.target.value}) } size="small" iconPosition='left'   fluid className='mb-1 shadow-sm' />
    //                 <Input icon='asl' type='number' value={articleNow.Prix_vente} placeholder='Prix vente'  onChange={ (e) => {articleNow.Prix_vente = e.target.value}} size="small" iconPosition='left'   fluid className='mb-1 shadow-sm' />
    //                 <Input icon='asl' type='number' value={articleNow.Quantite} placeholder='qte'  onChange={ (e) => {articleNow.Quantite = e.target.value}} size="small" iconPosition='left'    fluid className='mb-1 shadow-sm' />
                    
    //                 <br />
    //                 <Button disabled={updateBtnState}  className='rounded-pill bg-system-btn' onClick={AddArticleToList}>  <Icon name='edit outline' /> Ajouter</Button>
    //             </div>
    //     </>)
    // }
    
    return ( <>
            <BreadCrumb links={GConf.BreadCrumb.stockBE} />
            <br />
            <div className='row'>
                <div className='col-12 col-lg-5'>
                    <div className="mb-4 sticky-top" style={{top:'70px'}}>
                        
                        <AddArticles loadingPage={loadingPage} tableData={tableData} GetArticleData={GetArticleData} EnterKyPressed={EnterKyPressed} articleNow={articleNow} setArticleNow={setArticleNow} updateBtnState={updateBtnState} AddArticleToList={AddArticleToList} focusPrixA={focusPrixA} focusPrixV={focusPrixV} focusQte={focusQte} />
                        <ButtonsCard />
                    </div>
                </div>
                <div className='col-12 col-lg-7'>
                        <h5>Listes des Articles</h5>    
                        {bonsD.articles.map( (val) => <ArticleListCard key={val.id} dataA={val}/>)}
                        <br />  
                </div>
            </div>
            <FrameForPrint frameId='printBES' src={`/Pr/Stock/bonE/${BE_ID}`} />

    </> );
}

export default BonsEntre;