import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Icon, Input, Loader } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
// import BreadCrumb from '../Assets/breadCrumb';
// import usePrintFunction from '../../Assets/Hooks/printFunction';
// import FrameForPrint from '../../Assets/frameForPrint';
// import useSaveNotification from '../../Assets/Hooks/saveNotifFunction';

function BonsEntre() {
    /*#########################[Const]##################################*/
    const [bonsD, setBonsD] = useState({genre:'Entre', fournisseur: '', articles:[]})
    const [BE_ID, setBEID] = useState('')
    const [articleNow, setArticleNow] = useState([])
    const [tableData, setTableData] = useState([]);
    const [fournisseurList , setFSList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [toUpdatedList, setTUpList] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true)
    const [loaderState, setLS] = useState(false)
    const [updateBtnState, setUpdateBS] = useState(false)
    const [autofocusState, setAutoFocus] = useState(false)
     
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        //get stock
        axios.post(`${GConf.ApiLink}/stock`, {
            PID : GConf.PID,
          })
          .then(function (response) {
               let TableNow = []
               response.data.map( (dta) => {TableNow.push({value : dta.A_Code, text : dta.Name, key: dta.PK})})
               setTableData(TableNow)
               setArticleList(response.data)   
               setLoadingPage(false)    
          }).catch((error) => {
            if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Chargement des article sur votre ordinateur  </div></>, GConf.TostInternetGonf)   
                let TableNow = []
                Offline.stock.map( (dta) => {TableNow.push({value : dta.A_Code, text : dta.Name, key: dta.PK})})
                setTableData(TableNow)
                setArticleList(Offline.stock)
            }
        });
        axios.post(`${GConf.ApiLink}/fournisseur`, {
            PID : GConf.PID,
          })
          .then(function (response) {
               setFSList(response.data)      
          }).catch((error) => {
            if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Chargement des article sur votre ordinateur  </div></>, GConf.TostInternetGonf)   
            }
        });

    }, [])

    /*#########################[Function]##################################*/
    const AddArticleToList = (barecodeValue)=>{
        if (!articleNow.A_Code) {toast.error("Code à barre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Name || articleNow.Name === '') {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else{
            const searchObject = bonsD.articles.find((article) => article.A_Code == barecodeValue);
            if (searchObject) {
                toast.error('Deja exist ', GConf.TostSuucessGonf)
                setAutoFocus(false)
            } else {
                 
                let arrayToAdd = {id: articleNow.PK , A_Code: barecodeValue, Name: articleNow.Name,  prixToAdd : articleNow.Prix_vente}
                bonsD.articles.push(arrayToAdd) 
                //setArticleNow([{}]) 
                setAutoFocus(false)
            }
        }

    }
    const GetArticleData = (value) =>{
        const searchObject = articleList.find((article) => article.A_Code == value);
        
        if (searchObject) {
            // if (searchObject.A_Code != articleNow.A_Code) {
            //     setArticleNow(searchObject)
            // }
            setArticleNow(searchObject)
            let arrayToAdd = {id: searchObject.PK , A_Code: searchObject.A_Code, Name: searchObject.Name,  prixToAdd : searchObject.Prix_vente}
            bonsD.articles.push(arrayToAdd) 
            setArticleNow([{}]) 
            setAutoFocus(true)
             
            
        }else{
            toast.error('Article Indéfine ', GConf.TostSuucessGonf)
            setArticleNow([{}]) 
            setAutoFocus(true)
        }
    }
    const SaveTicketToPrint = () =>{
        setLS(true)
        setUpdateBS(true)
        
        axios.post(`${GConf.ApiCaisseLink}/sk/ticket-prix`, {
            listeDesPrix: bonsD.articles.map(item => `(${item.A_Code})`).join(', '),
          })
          .then(function (response) {    
                toast.success("Ticket Enregistrer !", GConf.TostSuucessGonf)
                setLS(false)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Les Ticket n'ont pas étéz enregistreé </div></>, GConf.TostInternetGonf)   
              setLS(false)
            }
          });

    }
    const RemoveArticleFromList = (value) =>{
        const searchObject= bonsD.articles.findIndex((article) => article.A_Code === value);
        const searchObjectTwo = toUpdatedList.findIndex((article) => article[0] === value);
        bonsD.articles.splice(searchObject, 1);
        toUpdatedList.splice(searchObjectTwo, 1);
        let resteArticles = bonsD.articles;
        setBonsD({...bonsD, articles: resteArticles})
    }
    const PrintFunction = (frameId) =>{  }
    const EnterKyPressed = (e) =>{
        if (e.which == 13) {
            GetArticleData(e.target.value)
        }  
         
        else {
             //console.log(e.which)
         } 
            
    }
    
    const AddByKeyPressed = (e) =>{
        if (e.which == 13) {
            AddArticleToList()
        }              
    }

    /*#########################[Card]##################################*/
    const ArticleListCard = (props) =>{
        return(<>
                    <div className='card shadow-sm p-2 mb-1 rounded-pill ps-4'>
                        <div className='row'>
                            <div className='col-6 text-start align-self-center'>
                                {props.dataA.Name}
                            </div>
                            <div className='col-5 align-self-center'><b>{props.dataA.prixToAdd.toFixed(3)}</b></div>
                            <div className='col-1 align-self-center'><Button disabled={updateBtnState} icon="times" className='rounded-circle p-2 text-white bg-danger' onClick={() => RemoveArticleFromList(props.dataA.A_Code)}></Button></div>
                        </div>
                    </div>
                </>)
    }
     
    const AddArticles = () =>{
        return (<>
                <div className={`card card-body  mb-2 border-div text-white ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-3' : '' }`}  >
 
                    <h5>Ajouter article</h5> 
                    {/* <datalist id="articlesList">
                        {tableData.map((test) =>
                        <option key={test.key} value={test.value}>{test.text}</option>
                        )}
                    </datalist> */}
                    {/* <Input icon='pin' list="articlesList"  onBlur={ (e) => GetArticleData(e.target.value)} size="small" iconPosition='left' placeholder='Desg'  fluid className='mb-1 shadow-sm' />  */}
                    <input type='text' className='form-control form-control-lg mb-3'    onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} />
                    <div className='m-2 mb-1 mt-3 text-secondary '><b> <span className='bi bi-upc '></span> Code a barre : {articleNow.A_Code} </b></div>
                    <div className='m-2 mb-1 mt-0 text-secondary'><b> <span className='bi bi-star-fill '></span> Desg : {articleNow.Name} </b></div>
                    <div className='m-2 mb-1 mt-0 mb-2 text-secondary'><b> <span className='bi bi-box2'></span> PRIX : {articleNow.Prix_vente} </b></div>
                    {/* <Input icon='asl' type='number' autoFocus={autofocusState} onKeyPress={(e) => AddByKeyPressed(e)} onChange={ (e) => {articleNow.Value = e.target.value}} size="small" iconPosition='left' placeholder='Valeur'  fluid className='mb-1 shadow-sm' /> */}
                    <br />
                    <Button disabled={updateBtnState}  className='rounded-pill bg-system-btn' onClick={AddArticleToList}>  <Icon name='edit outline' /> Ajouter</Button>

                    <br />
                    <br />
                    <Button disabled={false}  className='rounded-pill bg-danger text-white' size='large' onClick={SaveTicketToPrint}>  <Icon name='edit outline' /> ENREGISTRER </Button>
                    <br />
                </div>
        </>)
    }

    const ProgressLoadingBar = (props) =>{
        return(<>
            <div className={`progress-bar-loading ${props.display == true ? '': 'd-none'}`} style={{marginTop:'1px'}}>
                    <div className="progress-bar-loading-value bg-danger"></div>
                </div>
            </>)
    }

    return ( <>
            <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
                <BackCard data={InputLinks.backCard.mf}/>
                <ProgressLoadingBar  display={loadingPage} />
                <br />

                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-5'>
                            <div className="mb-4 sticky-top" style={{top:'70px'}}>
                                <AddArticles />
                                
                            </div>
                        </div>
                        <div className='col-12 col-lg-7'>
                                <h5 className={`${InputLinks.themeMode == 'dark' ? 'text-white' : '' }`} >Listes des Articles</h5>    
                                {bonsD.articles.map( (val) => <ArticleListCard key={val.id} dataA={val}/>)}
                                <br />  
                        </div>
                    </div>
                </div>
            </div>
           
    </> );
}

export default BonsEntre;