import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { NavLink, useParams } from 'react-router-dom';
import { Button,  Icon, Popup, Select, Tab} from 'semantic-ui-react';
import SKLT from '../../AssetsM/usedSlk';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb'
import { toast } from 'react-toastify';
import FrameForPrint from '../Assets/frameForPrint';
import usePrintFunction from '../Assets/Hooks/printFunction';
import { Input } from 'semantic-ui-react';
import TableGrid from '../Assets/tableGrid';
import { _ } from 'gridjs-react';

function TicketDePrixPage() {
    /*#########################[Const]##################################*/
    let [caissesR, setCaisseR] = useState([])
    let [electroR, setElectroR] = useState([])
    let [Revenue, seRevenueData] = useState([])
    let [depenseData, setDepenseData] = useState([])
    let [sarefData, setSarefData] = useState([])
    let [bonsData, setBonsData] = useState([])
    let [mgDepotData, setMgDepotData] = useState([])
    let [budgetData, setBudgetData] = useState([])

    const [loading , setLoading] = useState(false)
    const [modalS, setModalS] = useState(false)
     
    const budgetListGenres = [
      {id:1, text:'DEPENSES', value:'DEPENSES'},
      {id:1, text:'DEPOT', value:'DEPOT'},
      {id:1, text:'REVENUE', value:'REVENUE'},
      {id:1, text:'BONS', value:'BONS'},
      {id:1, text:'SAREF', value:'SAREF'},
    ]
    
    const panes = [
      {
        menuItem: { key: 'attent',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> CAISSES</b></span> , className:'rounded-pill'},
        render: () => <TableGrid tableData={caissesR} columns={['Date','Caisse1','Caisse2','Voir']} />,
      },
      {
        menuItem: { key: 'vu',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> REVENUE </b></span> , className:'rounded-pill' },
        render: () => <TableGrid tableData={Revenue} columns={['Date','Valeur', 'description','Voir']} />,
      },
      {
        menuItem: { key: 'accept',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> ELECTRO</b></span> , className:'rounded-pill' },
        render: () => <TableGrid tableData={electroR} columns={['De','Vers', 'Valeur','Voir']} />,
      },
      {
        menuItem: { key: 'dthyrtrt',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> SAREF</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={sarefData} columns={['Date','Valeur', 'description','Voir']} />,
      },
      {
        menuItem: { key: 'fjfggf',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> BONS</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={bonsData} columns={['Date','Valeur', 'description','Voir']} />,
      },
      {
        menuItem: { key: 'refuse',  content: <span className='text-secondary'><b><span className='bi bi-dash'></span> DEPENSES</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={depenseData} columns={['Date','Valeur', 'description','Voir']} />,
      },
      {
        menuItem: { key: 'dthrtrt',  content: <span className='text-secondary'><b><span className='bi bi-dash'></span> DEPOT</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={mgDepotData} columns={['Date','Valeur', 'description','Voir']} />,
      },
      
    ]

    const panesAdd = [
      {
        menuItem: { key: 'attent',  content: <span className='text-secondary'><b> AJOUTER</b></span> },
        render: () => <AjouterCard />,
      },
      {
        menuItem: { key: 'vu',  content: <span className='text-secondary'><b> CAISSES </b></span>  },
        render: () => <AjouterCaissesCard />,
      },
      {
        menuItem: { key: 'accept',  content: <span className='text-secondary'><b> ELECTRO </b></span>  },
        render: () => <AjouterElectroCard />,
      },
       
      
    ]
    /*#########################[UseEffect]##################################*/
    
    useEffect(() => {
      axios.post(`https://api.system.ansl.tn/API/Admin/familia/finance`, {
          TAG: 'TAG',
      })
      .then(function (response) {

              let articleListContainer = []
              response.data.caissesR.map( (getData) => articleListContainer.push([
              getData.RC_Date,
              parseInt(getData.Caisse_1).toFixed(3),
              parseInt(getData.Caisse_2).toFixed(3),
              _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert((parseInt(getData.Caisse_1)+parseInt(getData.Caisse_2)).toFixed(3))}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setCaisseR(articleListContainer) 
              
              let electroCont = []
              response.data.electroR.map( (getData) => electroCont.push([
                  getData.De_Date,
                  getData.Vers_Date,
                  parseInt(getData.Carnet_Value).toFixed(3),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setElectroR(electroCont)

              let revenueCount = []
              let RevenueList = response.data.budgetData.filter((data) => data.Subgenre === 'REVENUES')
               
                  RevenueList.map( (getData) => revenueCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              seRevenueData(revenueCount)

              let depenseCount = []
              let DepenseList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPENSES')
               
                  DepenseList.map( (getData) => depenseCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setDepenseData(depenseCount)

              let depotCount = []
              let DepotList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPOT')
               
                  DepotList.map( (getData) => depotCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setMgDepotData(depotCount)

              let sarefCount = []
              let SarefList = response.data.budgetData.filter((data) => data.Subgenre === 'SAREF')
               
                  SarefList.map( (getData) => sarefCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setSarefData(sarefCount)

              let bonsCount = []
              let BonsList = response.data.budgetData.filter((data) => data.Subgenre === 'BONS')
               
                  BonsList.map( (getData) => bonsCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setBonsData(bonsCount)
           
          setBudgetData(response.data.budgetData)
          
      }).catch((error) => {
          if(error.request) {
          
          }
        });
    },[])

    /*#########################[Function]##################################*/
    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}

    const DeleteListe =  () =>{
        axios.post(`${GConf.ApiLink}/tools/ticket/prix/supprimer`, {
            PID : GConf.PID,
          })
          .then(function (response) {
                toast.success('Liste Vide', GConf.TostSuucessCaisseGonf)
                setLoading(true)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>     </div></>, GConf.TostInternetGonf)   
              setLoading(true)
              
            }
          });
    }

    const AjouterAuxListe =  () =>{}

    const openEditModal = (event,selected) =>{
      setModalS(true)
    }
    
    /*#########################[Card]##################################*/
    
    const AjouterCard = () =>{
        return(<>
                <div className='card card-body shadow-sm mb-2'>
                    <h5 className='mb-1'>Liste des prix: </h5>
                    <Select placeholder='Selectionnez' options={budgetListGenres}     className='w-100 shadow-sm rounded mb-3'  />
                    <Input type='number'  placeholder='Valeur' className='w-100 shadow-sm rounded mb-3' />
                    <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => AjouterAuxListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
                </div>
        </>)
    }
    const AjouterCaissesCard = () =>{
      return(<>
              <div className='card card-body shadow-sm mb-2'>
                  <h5 className='mb-1'>Liste des prix: </h5>
                   
                  <Input type='date'  placeholder='Valeur' className='w-100 shadow-sm rounded mb-3' />
                  <Input type='number'  placeholder='Caisse 1' className='w-100 shadow-sm rounded mb-3' />
                  <Input type='number'  placeholder='Caisse 2' className='w-100 shadow-sm rounded mb-3' />
                  <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => AjouterAuxListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
              </div>
      </>)
    }

    const AjouterElectroCard = () =>{
      return(<>
              <div className='card card-body shadow-sm mb-2'>
                  <h5 className='mb-1'>Liste des prix: </h5>
                   
                  <Input type='date'  placeholder='Valeur' className='w-100 shadow-sm rounded mb-3' />
                  <Input type='number'  placeholder='Caisse 1' className='w-100 shadow-sm rounded mb-3' />
                   
                  <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => AjouterAuxListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
              </div>
      </>)
    }
    return ( <>
            <div className='container'>
                <h5>Budget Totale</h5> 
                <br />
                <div className="row">
                    <div className="col-12 col-lg-4">
                            <Tab menu={{width : panes.width,  secondary: false }} panes={panesAdd} />
                    </div>
                    <div className="col-12 col-lg-8">
                         <Tab menu={{ secondary: true }} panes={panes} />
                    </div>
                    
                </div>
                   
            </div>
    </> );
}

export default TicketDePrixPage;